.profile {
  /* border: 1px solid black; */
  text-align: right;
  margin: 15px;
}
.profile h3 {
  display: inline-block;
  margin: 0 20px;
}

.container {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Styles of Individual Data  */

.styledTable {
  border-collapse: collapse;
  font-size: 0.8em;
  font-family: sans-serif;
  /* max-width: 800px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  /* width: 98vw; */
  width: 80%;
  height: fit-content;
  /* border: 1px solid black; */
}
.styledTable thead tr,
.userTable thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
}
/* .headTr th {
      text-align: center;
    } */
.styledTable th,
.styledTable td,
.userTable th,
.userTable td {
  padding: 8px 8px;
}
.styledTable tbody tr {
  border-bottom: 1px solid #dddddd;
}
.styledTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styledTable tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.userDetails {
  text-align: center;
}

.styledTable tbody tr td {
  text-align: center;
}

.editBtn {
  border: none;
  color: white;
  padding: 8px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  background-color: #009879;
}

/* Styles of Sign up Users Data */
.userTable {
  width: 20%;
  border-collapse: collapse;
}
.userTable th {
  padding: 12px;
}
.userName {
  cursor: pointer;
}
.userName td {
  text-align: center;
}
