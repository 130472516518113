.addCoupon {
  width: 50%;
  margin: 5% auto;
  /* border: 1px solid black; */
}
.addCoupon form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  place-items: center;
}
.addCoupon input {
  border-radius: 12px;
  width: 250px;
  height: 40px;
  text-align: center;
}
.addCoupon button {
  border: none;
  width: 100px;
  height: 35px;
  border-radius: 8px;
  background-color: #009a36;
  color: white;
  margin: 15px;
}
.showCoupon {
  width: 70%;
  margin: 0 auto;
  border: 1px solid black;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  place-items: center;
}
.coupon {
  display: flex;
  margin: 20px;
  align-items: center;
}
.coupon h3 {
  margin: 15px;
}
.coupon button {
  background-color: #ff0000;
  color: white;
  text-align: center;
  align-items: center;
  border: none;
  padding: 8px;
  border-radius: 8px;
  width: 70px;
  height: 30px;
}
@media screen and (max-width: 767px) {
  .addCoupon {
    width: 70%;
  }
}
