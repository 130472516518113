/* Styles of Client Order Data */

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
button:hover {
  cursor: pointer;
}

.orderLength {
  margin: 15px;
  margin-top: 25px;
}
.orderLength h3 {
  font-size: 18px;
}

.container {
  /* width: 100%; */
  margin-top: 100px;
}

.orders {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 35px;
}
.order {
  width: 350px;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ff7f7f;
  border-radius: 12px;
}

.pickupDetails,
.dropDetails {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid black; */
}

.pickTime {
  text-align: center;
}
.pickTime p {
  /* margin-top: 0 -10px 0 0; */
  margin: 0;
  margin-top: -10px;
}
.pickStatusRider {
  display: flex;
  align-items: center;
}

.pickStatus {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: inline-block;
  margin: 15px;

  /* border: 1px solid black; */
}
.blue {
  background-color: rgb(39, 208, 255);
}

.green {
  background-color: #9dff00;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pickRider {
  font-size: x-large;
}

.distancePrice {
  margin-left: 15px;
}

.moreDrop1 a,
.moreDrop2 a {
  border: 1px solid #ff4141;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  margin: 10px auto 0 auto;
  display: flex;
  padding: 4px 0;
  justify-content: space-between;
  color: black;
  text-decoration: none;
  font-weight: 600;
}
.moreDrop span {
  font-size: 18px;
}

.mapsEdit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mapsEdit button {
  width: 80px;
  height: 30px;
  background-color: #ff9696;
  /* background-color: rgb(39, 208, 255); */
  /* background-color: #9dff00; */
  /* color: white; */
  border-radius: 5px;
  border: none;
}

.mapsEdit a {
  text-decoration: none;
}

.edit {
  /* margin-left: 100px; */
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px 5px;
}
.editIcon {
  font-size: larger;
}

.orderData {
  /* border: 1px solid black; */
  margin-top: 100px;
  text-align: center;
}
.orderData button {
  margin-right: 70px;
  border: none;
  color: white;
  padding: 8px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  /* background-color: #008cba; */
  background-color: #009879;
}

/* End */

/* .order form {
    border: 1px solid grey;
  } */

.weight,
.pickUp,
.drop {
  width: 50%;
  margin: 3% auto;
  border: 1px solid grey;
}
.weight {
  border: none;
}
/* .weight h3 {
    margin-left: 35px;
  } */

.weightClass {
  display: flex;
  align-items: center;
  text-align: center;
}
.weightClass p {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid silver;
  margin: 0 auto;
  cursor: pointer;
}
/* .weightClass p:hover {
    border: 1px solid red;
  } */
.selected {
  border: 1px solid purple;
}
.addresses label,
.secureParcel label {
  margin: 16px 0 16px 15px;
  font-weight: bold;
}
.pickUp,
.drop {
  background-color: white;
}
.pick {
  display: block;
  margin-left: 15px;
}
.courierDetails input {
  width: 80%;
  border: 1px solid silver;
  border-radius: 4px;
  padding: 12px;
  margin-left: 15px;
}
.numberContainer {
  border: 1px solid silver;
  width: fit-content;
  margin-left: 15px;
}
.numberContainer span {
  margin-left: 15px;
  margin-left: 0;
}
.courierDetails .number {
  border: none;
  border-radius: none;
  /* border-bottom: 1px solid black; */
  width: 60%;
  margin-left: 0;
}

.remarks {
  margin: 20px auto;
}

.packageType {
  width: 50%;
  margin: auto;
  background-color: white;
  padding: 15px 0;
  border: 1px solid grey;
}
.packageType input {
  margin-top: 10px;
  display: block;
}
.packageType p {
  display: inline-block;
  margin: 10px 10px;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 8px;
  cursor: pointer;
}

.secureParcel {
  width: 50%;
  margin: 20px auto;
  background-color: white;
  padding: 15px 0;
  border: 1px solid grey;
}

.secureParcel span {
  margin-left: 20px;
  /* color: rgba(150, 148, 147, 1); */
}

.secureParcel input {
  margin-top: 15px;
}
.secureParcel p {
  width: 70%;
  margin-left: 20px;
}

.bag {
  width: 50%;
  margin: 10px auto;
}
.bag input {
  width: fit-content;
}

/* .payment,
  .paymentMode {
    border: 1px solid black;
  } */

.payment {
  width: 50%;
  margin: 20px auto;
  background-color: white;
  padding: 15px 0;
  border: 1px solid grey;
}

.paymentbank {
  margin: 15px 20px;
}
.paymentbank img {
  width: 50%;
  height: 100%;
  background-size: contain;
}

.multipleAddresses {
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 5px;
  width: 60%;
  margin: 0 auto;
}
.multipleAddresses p {
  border: 1px solid silver;
  border-radius: 10px;
  margin: 5px 0;
  padding: 5px;
}
@media screen and (max-width: 600px) {
  .multipleAddresses {
    width: 95%;
  }
}

.total,
.access {
  width: 50%;
  margin: 20px auto;
  background-color: white;
  padding: 15px 0;
  /* border: 1px solid grey; */
  text-align: center;
}
.total h2 {
  font-weight: normal;
  margin-left: 20px;
}
.total button,
.access button {
  /* background-color: #ff4141; */
  width: 200px;
  /* color: white; */
  border-radius: 12px;
  border: none;
  padding: 15px;
  margin-left: 20px;
}
@media screen and (max-width: 600px) {
  .access {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .access button {
    width: 100px;
  }
}
.clause {
  /* background-color: #ff4141; */
  border-bottom: 2px solid #ff4141;
}
.total p {
  margin-left: 20px;
}
.franchiseContainer {
  /* border: 1px solid black; */
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  margin: 2% auto;
}
.franchiseTitle {
  /* display: block; */
  width: 50%;
  /* border: 1px solid black; */
  margin: 1% auto;
  /* text-align: center; */
}
.franchises {
  margin: 15px;
}
.franchises button {
  height: 30px;
  width: 80px;
  border-radius: 8px;
  border: 1px solid silver;
  /* font-weight: bold; */
  font-size: 18px;
}
.franchises button:hover {
  background-color: #ff4141;
  color: white;
  border: none;
}

@media screen and (max-width: 768px) {
  .weight,
  .pickUp,
  .drop,
  .packageType,
  .secureParcel,
  .bag,
  .payment,
  .total,
  .access {
    width: 80%;
  }
  .total button,
  .access button {
    margin: 20px;
  }
}

.placeOrder {
  margin: 10% auto;
  width: fit-content;
}
.placeOrder button {
  text-align: center;
  padding: 20px;
  border: none;
  border: 1px solid silver;
  background-color: #ff4141;
  color: white;
  border-radius: 12px;
}
.notLoggedin {
  width: 50%;
  margin: 20% auto;
  /* place-content: center; */
  /* border: 1px solid black; */
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.orderStatus1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderStatus1 button {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  margin-left: 20px;
  /* border: 1px solid black; */
  border: none;
}
.onTheWay {
  background-color: #9dff00;
}
.placed {
  background-color: rgb(39, 208, 255);
}
.delivered {
  background-color: #ff4141;
}

.orderStatus {
  /* border: 1px solid black; */
  width: 30%;
  margin: 20px auto;
}
.orderStatusButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.orderStatus button {
  width: 100px;
  height: 40px;
  /* border: 1px solid red; */
  border: none;
  font-size: 22px;
  background-color: transparent;
}
.orderStatus input {
  text-align: center;
}
.orderStatusInputs {
  display: block;
}
.orderStatus input::placeholder {
  font-weight: 700;
  color: black;
}
@media screen and (max-width: 600px) {
  .orderStatus {
    /* flex-direction: column; */
    width: 100%;
  }
}
/* .orderStatus input{
  
} */
.addresses {
  margin-bottom: 50px;
}
.required {
  color: red;
  font-weight: bold;
}
.notificationContainer {
  width: 70%;
  margin: 10% auto;
}
.notificationContainer tr {
  text-align: center;
}
.styledTable thead tr {
  text-align: center;
}

@media screen and (max-width: 568px) {
  .notificationContainer {
    width: 90%;
  }
}

.separate {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-evenly;
}
.separate h2 {
  cursor: pointer;
}
.packageType button {
  width: 100px;
  height: 40px;
  margin-left: 15px;
  border-radius: 12px;
  color: white;
  border: none;
  /* background: #3f51b5; */
  background-color: #ff4141;
}
/* thead th {
  border: 1px solid black;
} */
thead tr {
  background-color: #009879;
  color: white;
}
thead th,
tbody td {
  border-bottom: 1px solid rgb(113, 113, 113);
  border-right: 1px solid rgb(120, 120, 120);
}
